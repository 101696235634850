<template>
	<div id="card" :class="card.type === 'Submit' ? 'light' : ''">
		<h2
			v-if="card.hasOwnProperty('title')"
			v-text="card.title"
			class="hyphens"
		></h2>
		<p
			v-if="card.hasOwnProperty('description')"
			v-html="card.description"
			class="hyphens"
		></p>
		<div v-if="card.type === 'Details'">
			<Details :fields="card.value" @input="$emit('input', $event)" />
		</div>
		<div v-if="card.type === 'FreeformText'">
			<FreeformText
				@input="$emit('input', $event)"
				:is-required="card?.required"
			/>
		</div>
		<div v-if="card.type === 'Keyword'">
			<Keyword
				:keywords="card.options"
				:selections="card.value"
				:is-required="card?.required"
				:max="card?.max"
				@select="$emit('select', $event)"
			/>
		</div>
		<div v-if="card.type === 'Box'">
			<Box
				:options="card.options"
				:selections="card.value"
				:is-required="card?.required"
				:max="card?.max ? card.max : 0"
				@select="$emit('select', $event)"
			/>
		</div>
		<div v-if="card.type === 'File'">
			<File
				:accepts="card.accept"
				:is-required="card?.required"
				:unique-id="card.id"
				@upload="$emit('upload', $event)"
			/>
		</div>
		<div v-if="card.type === 'Submit'">
			<Submit />
		</div>
		<div v-if="card.type === 'Checkbox'">
			<CheckboxInput
				:options="card.options"
				:selections="card.value"
				:is-required="card?.required"
				@select="$emit('select', $event)"
			/>
		</div>
	</div>
</template>

<script>
	import FreeformText from './formComponents/FreeformText';
	import Details from './formComponents/Details';
	import Keyword from './formComponents/Keyword';
	import Box from './formComponents/Box';
	import File from './formComponents/File';
	import Submit from './formComponents/Submit';
	import CheckboxInput from './formComponents/CheckboxInput';
	export default {
		name: 'FormCard',
		components: {
			CheckboxInput,
			Submit,
			File,
			Box,
			Keyword,
			Details,
			FreeformText
		},
		props: {
			card: {
				type: Object,
				default: function _() {
					return { type: '' };
				}
			}
		},
		emits: ['input', 'select', 'upload']
	};
</script>

<style scoped>
	#card {
		padding: 20px 10px;
		background-color: var(--white);
		border-top: 7px solid var(--accent);
		text-align: center;
		box-shadow: #dfdfdf 2px 2px;
		margin-bottom: 20px;
	}

	h2 {
		margin: 0;
		padding-bottom: 15px;
		font-size: 1.1rem;
		color: var(--black);
	}

	p {
		margin: 0;
		padding: 20px;
		color: var(--text);
		border: 1px solid var(--light-grey);
		text-align: left;
		font-size: 0.9rem;
	}

	.hyphens {
		word-break: break-word;
		overflow-wrap: break-word;
		hyphens: auto;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
	}
</style>
