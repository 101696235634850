<template>
	<!--
	<div id="content">
		<HomeHeader />
		<main>
			<article class="text-center">
				<p>
					Are you starting a business and need a brand FAST? Or need
					to refresh an existing brand?
				</p>
				<p>
					Do you need a personal brand kit with your name, as a
					consultant, photographer or maybe a gardener?
				</p>
				<p>
					Perhaps you don't have time to learn how to design a logo or
					to make a brand kit? Are you worried about getting
					ripped-off by a fancy design agency?
				</p>
				<p>
					Do you have some ideas of what you want, but need help with
					the design and implementation?
				</p>
				<p>
					...or are you just curious about if this could work for you?
				</p>

				<h1 style="color: var(--accent)">
					Congratulations! You have come to the right place.
				</h1>

				<br />
				<br />
				<Offer />
			</article>

			<article id="about">
				<br />
				<br />
				<br />
				<h1>Why work with me?</h1>
				<h5>
					Trusting your brand design or brand refreshing to someone
					can be daunting.
				</h5>

				<div id="profile" class="row">
					<div class="col-xs-12">
						<img
							src="../assets/profile.png"
							alt="Nina A. Ignatius"
						/>
					</div>
					<div class="col-xs-12">
						<div>
							<p>
								My name is Nina Ignatius. I offer you more than
								30 years of global experience from prestigious
								design firms in France, Japan, the United
								Kingdom, Australia and Scotland, as well as my
								native Finland.<br /><br />Let me serve you with
								all my expertise. I will take away all the
								hassle of getting a great logo and brand
								look-and-feel for your business, by creating a
								custom made brand kit in no time.
							</p>
							<p>
								<img
									id="sign"
									src="../assets/logo2-dark.png"
									alt="Nina A. Ignatius"
								/>
							</p>
						</div>
					</div>
				</div>

				<div class="bg-green">
					<p>
						I have worked with small, big, new, old, traditional and
						modern clients, all wanting to refresh or create a new
						brand. My experience allows me to work with a wide array
						of clients to provide specific solutions to their
						branding needs.
					</p>
					<p>
						My proudest professional moment was being selected to
						<a
							href="https://www.forbes.com/sites/kathryndill/2013/06/25/40-women-to-watch-over-40-list-rewards-innovation-and-disruption/?sh=116e567a3982"
							><strong>Forbes "40 women over Forty"</strong></a
						>
						for my innovative and disruptive design.
					</p>
					<p>
						My client list includes Disney, Pepsico, Procter &
						Gamble, Diageo and more. I have worked on projects and
						designs for banks, chemical companies, airlines,
						amusement parks, food companies, pharmaceuticals and
						personal care companies for such varied products and
						offerings as softdrinks, beer, liquor, medication, pet
						food, dairy, perfume, hair products, sweets, crisps,
						canned food...you name it.
					</p>
					<p id="testimonials">
						<strong
							>I am delighted to now be able to share my years of
							expertise with you, at a price you probably did not
							think was possible.</strong
						>
					</p>
				</div>
			</article>

			<article>
				<h1>Testimonials</h1>

				<blockquote>
					<p>
						"Nina is an experienced top professional in creating
						expressive brand designs. She knows how to crystallise
						the essence of a brand and express it visually through
						colours, shapes and typography. I have utilised her
						services more than once and will gladly do so in future
						brand development processes, as well."
					</p>
					<strong>- Reidar Wasenius, CCO, Breaks Finland</strong>
				</blockquote>

				<blockquote>
					<p>
						"Our new visual identity tells about us as forerunners
						and of our long experience in our field. We are proud of
						this heritage and knowledge. The brand refreshing that
						Nina did to our visual identity inspires us every day to
						achieve our goals. Collaboration with Nina was very
						enjoyable. She has bold and surprising ideas while
						keeping to the given brief. She also has a good
						understanding of the needs and constraints of us as her
						clients. Thanks to her creative thinking and happy
						persona Nina is suitable for demanding projects. Her
						work is efficient and she keeps to given schedules. We
						are very satisfied with the results of the development
						of our project and with our collaboration with Nina."
					</p>
					<strong>- Eija Lämsä, CEO of Sentina</strong>
				</blockquote>

				<blockquote>
					<p>
						"When commissioning a logo for our branded give-aways to
						lecturers, we hired Nina because of her long experience
						in the field and because she works efficiently and fast.
						She understood our brief immediately and gave us several
						great alternatives. We are relieved that we decided to
						not use a logo-robot online, and that we found Ninas
						valuable, quick service for our custom-made logo at a
						superb price."
					</p>
					<strong>- Hannu Ronimus, Vicar Töölö parish</strong>
				</blockquote>

				<blockquote>
					<p>
						"Nina's method of fast custom branding design is of
						great quality, as she works quicky and reliably thanks
						to her extensive experience."
					</p>
					<strong>- Ira Stening, Startup mentor, board member</strong>
				</blockquote>

				<blockquote>
					<p>
						"The process was clean and simple. The questionnaire was
						very easy to fill in and we were really able to pinpoint
						our ideal customer. As a result, we received many nice
						ideas related to our brand and product. Overall, the
						unique CustomBrandingPRO system works well for anyone
						needing a custom brand kit from a professional and
						no-hassle way."
					</p>
					<strong>- Mika Rytkönen, Noeton</strong>
				</blockquote>

				<blockquote>
					<p>
						"I have just recently become an entrepreneur and
						launched an importing company. I was in relatively fast
						need of a professional logo and visual guidelines for my
						brand.
						<br /><br />
						I bumped into Custom Branding PRO and found Nina’s Brand
						kit. The service sounded almost too good to be true
						because the assignment can be made just by filling out
						the well-thought ordering form and in 48h you will
						receive three suggestions for a brand kit.
						<br /><br />
						I had pretty clear ideas of what I wanted my brand to
						communicate and even a pre idea of a possible logo, so
						filling the brief form was relatively easy for me.
						<br /><br />
						I received three suggestions for my brand kit and was
						happy to see that Nina had the courage to challenge my
						original logo idea and introduce a fairly different
						approach as a third option. It was so good (and well
						justified) that I ended up choosing it.
						<br /><br />
						In addition I ordered the basic artwork package to have
						access to the vector and png versions of my new logo and
						to be able to design visual materials utilizing my brand
						kit with Canva.
						<br /><br />
						All in all I was very pleased with the customer-friendly
						work process and an extremely professional and stylish
						outcome. Can highly recommend Nina and her Custom
						Branding PRO for quality branding services!"
					</p>
					<strong>- Eeva von Fieandt, Libellule de Provence</strong>
				</blockquote>

				<blockquote>
					<h5 style="font-style: normal; font-weight: bold">
						See some recent case studies
						<a
							style="color: var(--accent) !important"
							href="https://www.canva.com/design/DAEwEILIJh4/oO2PAIJXaCHvZXSr3QVy0A/view#1"
							target="_blank"
							>here</a
						>.
					</h5>
					<video
						controls
						style="
							height: 100% !important;
							width: 100% !important;
							padding-top: 20px;
						"
					>
						<source
							src="../assets/case_studies.mp4"
							type="video/mp4"
						/>
						Video could not be loaded
					</video>
				</blockquote>
			</article>

			<article>
				<Offer />
			</article>

			<article>
				<h1>
					How can my offer of 297€ for three brand suggestions be
					possible?
				</h1>
				<p>
					<strong
						>&bullet; The formula works because YOU do your bit
						first</strong
					>
					so that I don't have to charge you for meetings,
					brainstorming sessions, writing notes, keeping a timesheet
					or doing the preparation work, that YOU are equipped to do
					better than me anyway.
				</p>
				<p>
					<strong
						>&bullet; The formula works because I have over 30 years
						of experience</strong
					>
					in this field that we do not need to re-invent the wheel.
				</p>
				<p>
					<strong
						>&bullet; The formula works because you know the exact
						cost</strong
					>
					and exactly what you can expect at every step.
				</p>

				<div id="points" class="row">
					<div class="col-md-6 col-xs-12">
						<p>
							<strong
								>Branding work can be done using a
								formula</strong
							><br />The big agencies won't tell you this, but the
							workflow is usually the same for all jobs, big or
							small. The cost of a project is determined by the
							reputation of the agency, how many people will be
							designated for the tasks, what touchpoints will need
							to be addressed and with what urgency.
						</p>
						<p v-show="brandingWorkExpand === true">
							Examples of brand touchpoints include the company's
							webpage, business cards, posters, roll-ups,
							uniforms, language or tone of voice, signage,
							interiors, icons, vehicles etc... wherever your
							brand might be present. This might start to feel
							overwhelming and expensive already, no? Not
							necessarily, as you will see...
						</p>
						<a
							@click="brandingWorkExpand = !brandingWorkExpand"
							v-text="
								brandingWorkExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>
					</div>
					<div class="col-md-6 col-xs-12">
						<p>
							<strong
								>The magic, and necessary, ingredients</strong
							><br />The first thing I want you to realise is that
							your company might not need, or ever even have, all
							the touchpoints mentioned above. The second thing
							you should know is that you do not need to
							commission all pieces at once.
						</p>
						<p v-show="theMagicExpand === true">
							Or ever. It all depends on the scale of your
							business and whether you can do the implementations
							on your own or with a freelancer you trust (for
							instance with me!). Most, if not all, branding work
							will at least need to address a logo (possibly
							including a logomark), colours and fonts.<br />In
							addition to the basic necessities above, a brand
							might need icons, illustrations, films, animations
							and specific layouts. From all of this, in unison
							with the customer's final choices, so-called Brand
							Guidelines can be made. This is to ensure the
							customer will adhere to the agreed set of visual
							rules to ensure the consistency of the brand. But
							there is no need to worry about that now.
						</p>
						<a
							@click="theMagicExpand = !theMagicExpand"
							v-text="
								theMagicExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>
					</div>
					<div class="col-md-6 col-xs-12">
						<p>
							<strong
								>What exactly is branding and why do you need
								it?</strong
							><br />You might have heard that the words brand and
							branding originate from marking cattle. Yes, the
							heated iron that was used to burn a specific mark or
							icon onto the hide of the cow so that they could be
							recognised to belong to a specific person.
						</p>
						<p v-show="whatIsBrandingExpand === true">
							<br />The mark was often the initials of the owner.
							The word for burn in Swedish is bränna and when a
							cattle was marked, or burned, it was bränd,
							pronounced brend.<br />Branding relies on smart and
							thoughtful unique graphic design = Visual
							communication, i.e., what you want to communicate to
							your potential/existing customer about your
							brand/company visually, that is aligned with, and
							true to, your company's values, mission and
							positioning, and in some cases, also hint at what
							the business or service is about.<br />As
							communication today is so visual, you cannot afford
							to ignore your brand and what it signals to your
							potential customers.
						</p>
						<a
							@click="
								whatIsBrandingExpand = !whatIsBrandingExpand
							"
							v-text="
								whatIsBrandingExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>
					</div>
					<div class="col-md-6 col-xs-12">
						<p>
							<strong>Let's bake a cake together...</strong><br />
							Just kidding. But first, think of the necessary
							basic ingredients for making a cake; eggs, flour,
							baking powder, sugar and butter.<br /><br />When
							creating a brand, the minimum visual "ingredients"
							are a logo, possibly a logomark, colours and fonts.
						</p>
						<p v-show="cakeExpand === true">
							A basic cake is hardly very exciting, so let's give
							it some flavour. Zingy lemon, or maybe sweet
							chocolate? Maybe add some exciting spices and then
							decorate it?<br /><br />Just as a Blackforest
							Chocolate cake is very different to a Strawberry
							sponge cake, the proportions of the ingredients and
							the chosen flavours and extras, are what gives a
							cake a certain character, bite and flavour. We also
							want it to be tasty, interesting, beautiful,
							memorable and morish.<br /><br />When designing a
							brand, we can, by adding a touch of finesse to
							soften up an otherwise harsh feel, and/or by
							changing colour palettes and hues, as well as for
							deciding on a layout (ex. centred text or aligned
							left), we can bake a whole new, personal cake, i.e.
							your brand.<br /><br />Every ingredient matters, and
							when you change the proportions of them and add a
							splash of this and a heap of that, the whole cake..
							sorry; brand, changes.
						</p>
						<a
							id="brand-kit"
							@click="cakeExpand = !cakeExpand"
							v-text="
								cakeExpand === true ? 'Show less' : 'Read more'
							"
						></a>
					</div>
				</div>
			</article>

			<article>
				<br />
				<h1>What will your brand kit include?</h1>
				<h5>
					In each of the three routes you will find the following:
				</h5>

				<br />
				<br />

				<div class="row">
					<div class="col-md-4 col-sm-6 col-xs-12"></div>
					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>The main logo</strong>
						</p>
						<p>
							It can be a word-mark (or name-mark, if your brand
							is your name), initials, with or without a logomark,
							possibly representing the industry or the service
							you provide. You can leave this up to me or make
							your own suggestion in the questionnaire further
							down. Your main logo can also include a very short
							tagline that describes what your brand or service is
							about.
						</p>
					</div>
				</div>

				<br />

				<div class="row">
					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>A secondary logo</strong>
						</p>
						<p>
							When you are in need of a more compact logo or when
							horizontal space is limited, think of a row of
							sponsor logos on a poster that need to fit next to
							eachother.
						</p>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>A favicon/stamp logo</strong>
						</p>
						<p>
							A favicon is a tiny picture in the URL-address line.
							You can also use it as a watermark on images and
							such.
						</p>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>Colour palette</strong>
						</p>
						<p>
							If you have a strong feeling of what colourways will
							suit your brand, you can suggest it in the
							questionnaire, otherwise, you can leave it up to me
							to interpret. More on colours further down.
						</p>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>Headline font</strong>
						</p>
						<p>
							As the name implies, this font is eye-grabbing. You
							can use it in PPT headlines, posters and on any
							merchandise or application.
						</p>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>Secondary font</strong>
						</p>
						<p>
							This is for so-called body-copy in longer texts. It
							is usually easier on the eye than headline font. The
							body-copy should not be all-caps, but the Headline
							font can.
						</p>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong>Inspirational images</strong>
						</p>
						<p>
							These will remind you of the feeling and colourways
							of your brand. They are not licensed to be used
							commercially, but can be used in internal
							communication.
						</p>
					</div>
				</div>
			</article>

			<article class="space-img-cols">
				<h1>Examples of my own three brand kit routes.</h1>
				<h5>
					My personality is a mix of female playfulness, stern
					minimalism and of laidback joy. This is how different three
					routes can look!
				</h5>

				<br />
				<br />

				<div class="row">
					<div class="col-md-4 col-sm-12">
						<img
							src="../assets/brand-kit1.png"
							alt="Brand-kit route 1"
						/>
					</div>

					<div class="col-md-4 col-sm-12">
						<img
							src="../assets/brand-kit2.png"
							alt="Brand-kit route 2"
						/>
					</div>

					<div class="col-md-4 col-sm-12">
						<img
							src="../assets/brand-kit3.png"
							alt="Brand-kit route 3"
						/>
					</div>
				</div>

				<br />

				<h5>
					Each route is a high-resolution printable A3 sized poster.
					Prints of your favourite route(s) can be ordered at a price
					of 29€/sheet.
				</h5>

				<br />
			</article>

			<article>
				<h1>Where to start?</h1>
				<p>
					If you feel that I am the right person to help you freshen,
					or design your brand from scratch, congratulations!
					<strong
						>First, I will need you to do a little bit of
						homework</strong
					>
					so that I can get to know your company, your brand and your
					wishes better.
					<strong
						>This is what allows me to offer extremely competitive
						pricing.</strong
					>
					Your input works as a factsheet, a basic brief and a
					blueprint, which in turn, will save you tons of money.<br /><br /><strong
						>Tell me more about your brand in the questionnaire when
						you sign up.</strong
					><br /><br />We associate certain colours, fonts and layouts
					with certain industries. So, will your brand look the same
					as everyone else's in the industry if you commission me to
					design it with my magic formula?
					<strong
						>The answers is "NO", provided that you do your bit
						first.</strong
					><br /><br />Although you are probably not a designer
					yourself, I bet that if I tell you to think of the colour
					green, you could associate it to a health brand, maybe
					natural or organic. If I say dark blue, you might think of a
					brand in the banking sector, or maybe in IT. Dusky rose? A
					beauty parlor. Yellow and red? A fast-food brand. Gold?
					Luxury watches, maybe. It is not always this obvious, but I
					think you can see what I mean.
				</p>
			</article>

			<article>
				<Offer />
			</article>

			<article class="text-center">
				<h1 style="color: var(--accent)">
					With an agency, you could expect to pay ten times that for a
					similar amount of work.
				</h1>
			</article>

			<article>
				<h1>The money-saving bit explained</h1>

				<p>
					<strong
						>A typical branding project with an agency can look like
						this:</strong
					>
				</p>
				<ol>
					<li>
						<p>
							Meeting between the client and the agency. (First
							you need to find the right agency for you, of
							course.) A brief is discussed.
						</p>
					</li>
					<li>
						<p>Needs are pinpointed.</p>
					</li>
					<li>
						<p>
							The agency gives an offer of the cost within a few
							days.
						</p>
					</li>
					<li>
						<p>
							The client makes a request for the timing (usually
							almost impossibly tight) and requests a lower cost.
							A written brief is agreed upon. Timing is agreed
							upon. Cost is also agreed.
						</p>
					</li>
					<li>
						<p>
							The agency makes a visual comparison of the client's
							competitors.
						</p>
					</li>
					<li>
						<p>The agency holds a brainstorming session or two.</p>
					</li>
					<li>
						<p>
							The agency makes mood boards and a couple of rough
							ideas to accompany them.
						</p>
					</li>
					<li>
						<p>A presentation is held to the client.</p>
					</li>
					<li>
						<p>
							The client agrees upon taking one route further (but
							will usually mix two routes together, which is often
							a designers nightmare).
						</p>
					</li>
					<li>
						<p>
							Another meeting is held. The budget has already been
							spent. Repeat steps 8-12 until the client is
							happy...
						</p>
					</li>
				</ol>

				<p>
					<strong
						>As you see, the process can be long and often
						frustrating, as well as costly. Now, if I told you that
						I can make this process substantially easier, faster and
						cheaper, would you believe me?</strong
					>
				</p>
			</article>

			<article>
				<h1>Your custom brand kit in three easy steps</h1>
				<div class="row">
					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong
								>1. Let me know your brands personality,
								attributes, ideal customer, possible
								competitors, and more.</strong
							>
						</p>
						<p v-show="letMeKnowYourBrandsPersonalityExpand">
							When you sign up you can fill the brand personality
							templates and choose the attributes that you wish
							your customers would associate with your brand. This
							will be your brand personality.<br />Fill the target
							customer profile template; Choose the attributes of
							your ideal customer - be specific.<br />Upload a
							visual comparison of your competition (if there are
							any) for me - to avoid similarities.<br />OPTIONAL:
							choose colour system(s) and font families that you
							feel is/are most relevant to convey your brand
							personality. You can very well leave this up to me
							as well, if you feel uncertain about it.
						</p>
						<a
							@click="
								letMeKnowYourBrandsPersonalityExpand =
									!letMeKnowYourBrandsPersonalityExpand
							"
							v-text="
								letMeKnowYourBrandsPersonalityExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong
								>2. I will analyse the prep-work you have done,
								which will be the blueprint from which I will
								transpose your words into visuals.</strong
							>
						</p>
						<p v-show="iWillAnalyseThePrepWorkExpand">
							I will create three different ideas, or "routes".<br />The
							three routes will each consist of a brand kit, which
							includes a logo (with possible logomark), secondary
							logo, small size logo (favicon), colour palette, and
							font examples, as well as some imagery to reflect
							the feel of the route that would appeal to your
							target customer and/or that represents you or your
							brand.
						</p>
						<a
							@click="
								iWillAnalyseThePrepWorkExpand =
									!iWillAnalyseThePrepWorkExpand
							"
							v-text="
								iWillAnalyseThePrepWorkExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>
					</div>

					<div class="col-md-4 col-sm-6 col-xs-12">
						<p>
							<strong
								>3. You will receive three design routes within
								*48h as a PDF.</strong
							>
							<br />
							<em style="font-size: 0.5rem; color: var(--white)"
								>*48h during working days Mon-Fri</em
							>
						</p>
						<p v-show="youWillReceiveThreeDesignExpand">
							If you want to proceed to refine and implement the
							new brand kit on your own, you are welcome to do so.
						</p>
						<a
							@click="
								youWillReceiveThreeDesignExpand =
									!youWillReceiveThreeDesignExpand
							"
							v-text="
								youWillReceiveThreeDesignExpand === true
									? 'Show less'
									: 'Read more'
							"
						></a>

						<br />
						<br />

						<h5
							style="
								font-size: 1.2rem;
								text-align: left;
								opacity: 0.7;
								padding: 0;
							"
						>
							The 297€ you paid covers all my work up to this
							point.
						</h5>
					</div>
				</div>
			</article>

			<article>
				<h1>
					However, if you prefer to continue to work with me with the
					implementations and artwork of your favourite route, I have
					some very special offers for you.
				</h1>
				<p class="text-center">
					(You do NOT need to commit to anything now. Wait until you
					get your three brand routes for 297€ and then see what your
					needs and wishes are.)
				</p>

				<p class="text-center">
					When you have chosen one of the three routes. I am offering
					you three options with great value.
				</p>

				<Pricing />

				<div class="notice text-center">
					<p class="detail">
						(You do NOT need to commit to anything now. Wait until
						you get your three brand routes for 297€ and then see
						what your needs and wishes are.)
					</p>
				</div>
			</article>

			<article>
				<Offer />
			</article>

			<article>
				<h1>Not yet ready to take the plunge?</h1>
				<h5
					style="
						font-size: 1.2rem;
						text-align: center;
						opacity: 0.7;
						padding: 0;
					"
				>
					You do not have to pay anything until you decide that
					everything feels right to you.
				</h5>

				<br />

				<p class="text-center">
					In just a moment, you will be able to prepare your brand
					blueprint for me, by making choices of attributes that you
					desire your brand to feel like or look like. In case you
					have an existing logo/look that needs refreshing, you need
					to still state your desired attributes that you want the
					logo/brand to be nudged towards.<br /><strong
						>You can tweak your replies as many times as you wish
						before submitting them to me.</strong
					>
					Not all questions are compulsory, just do your best.<br />Together
					with the competitor homework (you will simply upload a few
					.jpg's), your
					<strong
						>answers will become the blueprint from which I will
						start to transpose your words into visuals.</strong
					><br />Remember, the cost is only 297€.
				</p>
			</article>

			<article>
				<div class="bg-green">
					<h1>
						Below are instructions for the templates you will fill
						in.
					</h1>
					<p
						class="text-center"
						style="margin: 0; margin-bottom: 20px"
					>
						Make sure to read the text first.
					</p>
				</div>
			</article>

			<article class="space-img-cols">
				<h1>
					The 3 Cs are: Company, Customers and Competitors - the three
					semi-fixed environmental factors in your market.
				</h1>
				<p>
					You and I need to understand your particular three C’s in
					order for me to serve you in the best possible way.
				</p>

				<h1 style="color: var(--accent)" class="text-left">Company</h1>
				<h3>
					<strong>What is your Company's/brand's name?</strong>
				</h3>
				<p>
					First you will fill in your brand or company name, your
					field of work, as well as your contact details. (This can
					also be for instance your First + Surname if you want, ideal
					for a consultant, or personal branding, or why not even a
					wedding theme!)
				</p>

				<br />

				<p>
					<strong>
						I also need to know if your Company/brand is one or a
						mix of:</strong
					>
				</p>
				<ul>
					<li>
						<p>A business to consumer; B2C</p>
					</li>
					<li>
						<p>A business to business, B2B</p>
					</li>
					<li>
						<p>A service</p>
					</li>
					<li>
						<p>A product</p>
					</li>
				</ul>

				<div class="info-box">
					<p>
						<strong>B2C brand logos</strong>
						can be embellished and decorative to add “flavour” and
						feelings.
					</p>
					<p>
						<strong>B2B brand logos</strong>
						tend to be very simple and neutral, and often do not
						have very much meaning, which allows for longevity of a
						logo.
					</p>
					<p>
						<strong>Service brand logos</strong>
						focus on reliability, consistency and professionalism.
					</p>
					<p>
						<strong>Product brand logos</strong>
						tend to be very specific depending on who the ideal
						customer is, focusing on age and price points.
					</p>
				</div>

				<br />

				<h3>
					<strong>What is your Company's/brand's personality?</strong>
				</h3>
				<p>
					<strong
						>Brand Personality is a set of human characteristics
						that are attributed to a brand name.</strong
					>
					It is important for a company to accurately define its brand
					personality so it resonates with the correct consumer.
				</p>

				<br />

				<p>
					<strong>Brand Attributes;</strong>
				</p>
				<ul>
					<li>
						<p>
							are the core values that define the overall nature
							of the company and represent the essence of the
							brand.
						</p>
					</li>
					<li>
						<p>
							are the set of unique fundamentals and
							characteristics that identify the physical,
							character, and personality traits of the brand in
							the market and in the minds of the customers.
						</p>
					</li>
					<li>
						<p>
							are the functional and emotional associations that
							are assigned to a brand by its customers and
							prospects.
						</p>
					</li>
					<li>
						<p>
							<strong
								>are the basic elements for establishing a brand
								identity.</strong
							>
						</p>
					</li>
				</ul>

				<br />

				<p>
					<strong>What is the aim? </strong>
				</p>
				<ul>
					<li>
						<p>
							A company's brand should aim to elicit a positive
							emotional response from a targeted consumer segment.
						</p>
					</li>
					<li>
						<p>
							Branding aims to establish a significant and
							differentiated presence in the market that attracts
							and retains loyal customers.
						</p>
					</li>
					<li>
						<p>
							A brand is a name, term, symbol, or other feature
							that distinguishes an organization or product from
							its rivals in the eyes of the customer.
						</p>
					</li>
				</ul>

				<br />

				<p>
					<strong>How?</strong>
					You do this by
					<strong
						>choosing the attributes from the list that you
						<em>wish</em>
						your customers would associate with your brand.</strong
					>
				</p>

				<br />

				<p>
					There are five main types of brand personalities with common
					traits:
				</p>

				<div class="info-box bg-green">
					<p>
						<strong>Excitement:</strong>
						carefree, spirited, and youthful.
					</p>
					<p>
						<strong>Sincerity:</strong>
						kindness, thoughtfulness, and an orientation toward
						family values.
					</p>
					<p>
						<strong>Competence:</strong>
						successful, accomplished and influential, highlighted by
						leadership.
					</p>
					<p>
						<strong>Sophistication:</strong>
						elegant, prestigious, and sometimes even pretentious.
					</p>
					<p>
						<strong>Ruggedness:</strong>
						rough, tough, outdoorsy, and athletic.
					</p>
				</div>

				<br />

				<h3>
					<strong>Colour schemes and fonts</strong>
					<em> (OPTIONAL)</em>
				</h3>
				<p>
					<strong
						>Colours and fonts hold a significant meaning in
						marketing and human psychology.</strong
					>
				</p>
				<p>
					If you want, you can choose the colour system and font
					family you feel will work best to convey your chosen
					Company/brand attributes/personality.
					<strong>You can also leave this up to me.</strong
					><br />Colours can help you stand out while a poor colour
					choice can have a negative impact on your business. Colours
					broadly align with specific traits and aligning your colours
					with the type of personality you want to portray for your
					brand can help boost conversions. In addition, colour trends
					for men and women are also different. Hence, depending on
					your target audience you can choose which colours you want
					to use in your logo and your look and feel and associate
					with your brand.<br />You can choose one, two or three
					colour schemes and/or font families, as I will make three
					routes for you. If you feel very sure about a colour scheme,
					you can also choose just one.
					<strong>You can also leave it completely up to me.</strong>
				</p>

				<h1 style="font-size: 1.5rem" class="text-left">
					Colour scheme examples
				</h1>

				<p>
					<strong>Monotone palette -</strong>
					variations and shades of one colour
				</p>
				<p>Elegant, refined, classy, harmonious, quality, calm</p>

				<div class="row" style="text-align: center">
					<div
						class="col-md-4 col-sm-6 col-xs-12"
						style="text-align: center"
					>
						<img src="../assets/color1.png" alt="Monotone" />
					</div>
				</div>

				<br />
				<br />
				<p>
					<strong>Analogous colour palette -</strong>
					combines two or even three adjacent colours on the colour
					wheel such as yellow + green + blue
				</p>
				<p>Harmonious, fresh, easy on the eye, gentle, positive</p>

				<div class="row" style="text-align: center">
					<div
						class="col-md-4 col-sm-6 col-xs-12"
						style="text-align: center"
					>
						<img src="../assets/color2.png" alt="Analogous" />
					</div>
				</div>

				<br />
				<br />
				<p>
					<strong>Complimentary palette -</strong>
					combines opposite colours from the colour wheel, such as
					blue + orange, or red + green
				</p>
				<p>Strength, energy, vitality, playful, youthful, naive</p>

				<div class="row" style="text-align: center">
					<div
						class="col-md-4 col-sm-6 col-xs-12"
						style="text-align: center"
					>
						<img src="../assets/color3.png" alt="Contrast" />
					</div>
				</div>

				<br />
				<br />
				<h1 style="font-size: 1.5rem" class="text-left">
					Font family examples (there are thousands of fonts within
					each family!
				</h1>

				<div class="row" id="fonts">
					<div class="col-md-4 col-sm-12">
						<img src="../assets/font1.png" alt="Sans Serif" />
						<p>
							<strong>Modern, geometric, simple</strong>
						</p>
						<p>
							minimal<br />versatile<br />clean<br />straightforward<br />informal<br />legible<br />innovative<br />objective<br />authoritative<br />reliable
						</p>
					</div>

					<br />
					<br />

					<div class="col-md-4 col-sm-12">
						<img src="../assets/font2.png" alt="Serif" />
						<p>
							<strong>Elegant, refined, classic</strong>
						</p>
						<p>
							sophisticated<br />expensive<br />delicate<br />stable<br />warm
							and welcoming<br />respectable<br />mature<br />reliable<br />high-end<br />glamorous<br />luxurious
						</p>
					</div>

					<br />
					<br />

					<div class="col-md-4 col-sm-12">
						<img src="../assets/font3.png" alt="Script" />
						<p>
							<strong>Creative, personal, elegant</strong>
						</p>
						<p>
							classic<br />sophisticated<br />expensive<br />delicate<br />natural<br />artistic<br />unique<br />warm
							and welcoming<br />respectable<br />mature<br />reliable<br />high-end<br />glamorous<br />luxurious<br />
						</p>
					</div>
				</div>

				<h1 style="color: var(--accent)" class="text-left">Customer</h1>
				<p>
					<strong>Who is your ideal Customer?</strong>
				</p>
				<p>
					Identifying your target customer is the most important stage
					of your marketing plan.
				</p>
				<p>
					A wise place to start is to ask yourself “What problem does
					my product/service solve? and for whom?" It is important to
					define who your ideal customer is.
					<strong
						>Try not to be too broad as you can't be all things to
						all people. Find your niche.</strong
					>
				</p>
				<p>
					<strong>How?</strong>
					You do this by
					<strong
						>choosing the attributes from the list that best fit
						your ideal customer.</strong
					>
				</p>

				<ul>
					<li>
						<p>
							For certain brands, you might be interested in
							demographics like age and gender. For other ones,
							you might be interested more in psychographics like
							interests and opinions.
						</p>
					</li>
					<li>
						<p>
							You should try to specify the gender and age, local
							or global, the problem they have and why you/your
							company is best suited to do so. You know your
							industry best.
						</p>
					</li>
				</ul>

				<h1 style="color: var(--accent)" class="text-left">
					Competition
				</h1>
				<p>
					<strong>What do your Competitors look like?</strong>
				</p>
				<p>
					Different companies within the same industry seem to use
					similar colour palettes, fonts and have a somewhat similar
					overall feel. This is no coincidence. The feeling that
					different colours give the viewer have been documented for
					ages, and are pretty much universal.
				</p>
				<p>
					<strong
						>In order to avoid any risk of similarity to your
						competition, you need to share these with me.</strong
					>
				</p>
				<p>
					I ask you to send some screenshots of 2-3 main competitors'
					web pages and/or logos. You can upload the screenshots in
					the questionnaire.
				</p>
				<p>
					Would it not be devastating if there was another company,
					even a direct competitor, with the same colours, fonts and a
					similar logo? Yes, it would.
					<strong
						>This is why it is so important that you need to do your
						homework as you know your industry best.</strong
					>
				</p>
			</article>

			<article>
				<Offer />
			</article>

			<article>
				<h1>
					Remember the anecdote of how mixing colours, fonts, scale
					and proportions are like choosing ingredients for a cake?
				</h1>
				<p>
					<strong
						>Design and branding can seem daunting and this is why I
						am here for you!</strong
					>
					With my 30+ years of experience and your prep-work, we will
					together create a custom made brand just for you.
				</p>

				<br />

				<h2>Do you feel ready to fill in your blueprint?</h2>

				<div class="text-center">
					<p id="contact">
						<router-link
							class="button"
							to="get-started"
							target="_blank"
							>Sign up
						</router-link>
					</p>
				</div>
			</article>

			<article>
				<h1>Cannot find what you are looking for?</h1>
				<h5>
					Please feel free to contact me directly for larger or more
					complex projects.
				</h5>

				<br />

				<form
					class="row"
					method="POST"
					action="https://formspree.io/f/xjvjenwy"
				>
					<div class="col-xs-12">
						<div class="row">
							<div class="col-xs-12">
								<input
									name="Name"
									type="text"
									placeholder="Name"
									required
								/>
							</div>
							<div class="col-xs-12">
								<input
									name="E-mail"
									type="email"
									placeholder="E-mail"
									required
								/>
							</div>
							<div class="col-xs-12">
								<textarea
									name="Message"
									placeholder="Message"
									required
								></textarea>
							</div>
						</div>
					</div>

					<div class="col-xs-12">
						<input
							class="button"
							type="submit"
							value="Get in touch"
						/>
					</div>
				</form>

				<br />

				<h5>Or just email me on</h5>
				<br />

				<div class="text-center">
					<a href="mailto:nina@custombrandingpro.com"
						>nina@custombrandingpro.com</a
					>
				</div>
			</article>

			<article id="footer">
				<div class="row">
					<div class="col-md-6 col-sm-12">
						<p>
							<a href="tel:+358408350500">+358 408350500</a>
						</p>
						<p>
							<a href="mailto:nina@custombrandingpro.com"
								>nina@custombrandingpro.com</a
							>
						</p>
						<br />
						<p>Address:</p>
						<p>Hallkullanniemi 10A</p>
						<p>00990 Helsinki</p>
						<p>FINLAND</p>
					</div>

					<div class="col-md-6 col-sm-12">
						<h3>
							<a>Hire me</a>
						</h3>
						<h3>
							<a>Career opportunities</a>
						</h3>
					</div>
				</div>

				<div class="row bg-gray">
					<div class="col-xs-12">
						<p v-html="`&copy; CustomBrandingPRO ${year}`"></p>
					</div>
				</div>
			</article>
		</main>
	</div> -->
	<div><br /></div>
</template>

<script>
	export default {
		name: 'Home',
		mounted() {
			window.location = 'https://custombranding.pro';
		},
		data() {
			return {
				brandingWorkExpand: false,
				theMagicExpand: false,
				whatIsBrandingExpand: false,
				cakeExpand: false,
				letMeKnowYourBrandsPersonalityExpand: false,
				iWillAnalyseThePrepWorkExpand: false,
				youWillReceiveThreeDesignExpand: false
			};
		},
		computed: {
			year() {
				const d = new Date();

				return d.getFullYear();
			}
		}
	};
</script>

<style scoped>
	#content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	* {
		color: var(--black);
	}

	article {
		padding: 20px 25vw;
		margin: 0 auto;
	}

	article h1 {
		font-size: 2.5rem;
		margin: 0;
		text-align: center;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	article h2 {
		color: var(--accent);
		font-size: 2rem;
		margin: 0;
		text-align: center;
		padding-top: 20px;
	}

	article h3 {
		font-size: 1.4rem;
	}

	article h3 em {
		font-style: normal;
		font-size: 1rem;
	}

	article h5 {
		color: var(--black);
		opacity: 0.8;
		text-align: center;
		margin: 0;
		padding-top: 5px;
		font-size: 1.3rem;
	}

	article p {
		line-height: 1.8;
	}

	article img {
		margin: 0 auto;
		box-shadow: 4px 4px 4px 2px #eee;
		border: 2px solid var(--light-grey);
	}

	article button {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	article a {
		text-decoration: none;
	}

	article a:hover,
	article a:active {
		opacity: 0.8;
		cursor: pointer;
	}

	#profile {
		margin: 0 auto;
		margin-top: 50px;
		width: 100%;
	}

	#profile div {
		background-color: var(--light-grey);
		text-align: center;
	}

	#profile p {
		text-align: center;
		font-size: 1.2rem;
		margin: 0;
		padding: 0 20px;
	}

	#profile img {
		max-width: 300px;
		padding: 20px;
	}

	#sign {
		padding-top: 20px;
		max-width: 220px !important;
	}

	blockquote {
		width: 80%;
		text-align: center;
		margin: 0 auto;
		padding: 30px;
	}

	blockquote p {
		margin: 0;
		padding-bottom: 10px;
	}

	blockquote strong {
		font-weight: normal;
		font-style: italic;
	}

	#points {
		padding-top: 50px;
	}

	#points div {
		padding-bottom: 10px;
		padding-left: 10px;
	}

	#points p {
		font-size: 0.9rem;
		margin: 0;
		padding-bottom: 5px;
		padding-top: 10px;
	}

	#points a {
		color: var(--accent);
		font-weight: bold;
	}

	.notice p {
		color: var(--accent);
		font-size: 1.2rem;
	}

	.notice p strong {
		color: var(--accent);
	}

	.notice .detail {
		color: var(--black);
	}

	form {
		text-align: center;
	}

	form input,
	form textarea {
		width: 90%;
		padding: 15px 10px;
		border-radius: 5px;
		margin: 0 auto;
		border-width: 1.2px;
	}

	form textarea {
		width: 90%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: block;
	}

	form .col-xs-12 {
		padding: 5px;
		text-align: center;
	}

	#footer {
		margin-top: 50px;
		background-color: rgba(0, 0, 0, 0.05);
		padding: 0 30px;
	}

	#footer .bg-gray {
		text-align: center;
		padding: 10px;
	}

	#footer .bg-gray * {
		text-align: center;
		width: 100%;
	}

	#footer a {
		text-decoration: none;
		color: var(--accent);
	}

	#footer h3 {
		font-size: 1.2rem;
	}

	#footer a,
	#footer p {
		font-size: 0.9rem;
	}

	.text-center {
		text-align: center;
	}

	.text-left {
		text-align: left;
	}

	.space-img-cols [class*='col-'] {
		padding: 10px;
		width: 100%;
		text-align: center;
	}

	.space-img-cols img {
		max-width: 100%;
	}

	.bg-green {
		background-color: var(--accent);
		padding: 20px;
		margin-top: 30px;
	}

	.bg-green * {
		color: var(--white);
	}

	.bg-gray {
		background-color: #88959e;
	}

	.bg-gray * {
		color: var(--white);
	}

	@media screen and (max-width: 870px) {
		header h1 {
			font-size: 2rem;
		}

		header p {
			font-size: 1rem;
		}

		header,
		article {
			padding: 10px;
		}

		header div {
			max-width: 100%;
		}

		article {
			max-width: 90%;
		}

		article h1 {
			font-size: 1.5rem;
			padding-top: 20px;
			padding-bottom: 20px;
		}

		article h2 {
			font-size: 1rem;
		}

		article h3 {
			font-size: 0.9rem;
		}

		article h3 em {
			font-size: 0.8rem;
		}

		article p {
			line-height: 1.4;
		}

		#profile img {
			max-height: 600px;
		}
	}
</style>
