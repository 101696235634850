<template>
	<div id="form" class="container-fluid">
		<span v-if="basicArtworkPrice">
			<stripe-checkout
				ref="checkoutRef"
				:pk="pk"
				mode="payment"
				:line-items="[
					{
						price: basicArtworkPrice,
						quantity: 1
					}
				]"
				:success-url="`https://custombrandingpro.com/thank-you/${clientData.id}`"
				:cancel-url="`https://custombrandingpro.com/cancel/${clientData.id}/${customDeletionId}`"
				:client-reference-id="clientData.id"
				:customer-email="clientData.email"
				submit-type="pay"
			/>
		</span>

		<div id="lang-select">
			<div class="row">
				<div class="col-xs-5">
					<p @click="setLang('eng')">ENG</p>
				</div>
				<div class="col-xs-2">
					<p class="ignore-hover">/</p>
				</div>
				<div class="col-xs-5">
					<p @click="setLang('fi')">FI</p>
				</div>
			</div>
		</div>

		<form @submit.prevent="sendForm()">
			<div class="row">
				<div class="col-lg-5 col-md-6 col-sm-12">
					<div class="row">
						<div class="col-lg-6 col-xs-12">
							<div class="row">
								<div
									v-for="card in questions.slice(0, 5)"
									:key="card.id"
									class="col-xs-12"
								>
									<FormCard
										:card="card"
										@input="setValue(card.id, $event)"
										@select="selectValue(card.id, $event)"
										@upload="
											setUploadValue(card.id, $event)
										"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-xs-12">
							<div class="row">
								<div
									v-for="card in questions.slice(5, 8)"
									:key="card.id"
									class="col-xs-12"
								>
									<FormCard
										:card="card"
										@input="setValue(card.id, $event)"
										@select="selectValue(card.id, $event)"
										@upload="
											setUploadValue(card.id, $event)
										"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-7 col-md-6 col-sm-12">
					<div class="row">
						<div class="col-lg-4 col-xs-12">
							<div class="row">
								<div
									v-for="card in questions.slice(8, 10)"
									:key="card.id"
									class="col-xs-12"
								>
									<FormCard
										:card="card"
										@input="setValue(card.id, $event)"
										@select="selectValue(card.id, $event)"
										@upload="
											setUploadValue(card.id, $event)
										"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-xs-12">
							<div class="row">
								<div
									v-for="card in questions.slice(10, 12)"
									:key="card.id"
									class="col-xs-12"
								>
									<FormCard
										:card="card"
										@input="setValue(card.id, $event)"
										@select="selectValue(card.id, $event)"
										@upload="
											setUploadValue(card.id, $event)
										"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-xs-12">
							<div class="row">
								<div
									v-for="card in questions.slice(12, 16)"
									:key="card.id"
									class="col-xs-12"
								>
									<FormCard
										:card="card"
										@input="setValue(card.id, $event)"
										@select="selectValue(card.id, $event)"
										@upload="
											setUploadValue(card.id, $event)
										"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-5 col-lg-offset-7">
					<div class="row">
						<div class="col-xs-12">
							<FormCard :card="questions[16]" />
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import FormCard from '../components/form/FormCard';
	import {
		asyncFirePost,
		fireSignIn,
		asyncFireSignIn,
		asyncFireUploadFormFiles
	} from '../db';
	import { v4 as uuidv4 } from 'uuid';
	import { validateFileUpload } from '../files';
	import { StripeCheckout } from '@vue-stripe/vue-stripe';

	export default {
		name: 'Form',
		components: {
			FormCard,
			StripeCheckout
		},
		data() {
			return {
				pk: '',
				paymentLink: '',
				basicArtworkPrice: '',
				failedCheckoutOnce: false,
				clientData: {
					id: '',
					email: ''
				},
				customDeletionId: '',
				questionsByLang: {
					eng: [
						{
							id: 0,
							uniqueId: 'you',
							type: 'Details',
							title: 'You',
							value: [
								{
									shortname: 'name',
									placeholder: 'Name *',
									value: '',
									type: 'name',
									required: true
								},
								{
									shortname: 'email',
									placeholder: 'Email *',
									value: '',
									type: 'email',
									required: true
								},
								{
									shortname: 'phoneNumber',
									placeholder: 'Phone number',
									value: '',
									type: 'phone'
								},
								{
									shortname: 'position',
									placeholder: 'Position/title',
									value: '',
									type: 'text'
								}
							]
						},
						{
							id: 1,
							uniqueId: 'brandDetails',
							type: 'Details',
							title: 'The Company/brand',
							value: [
								{
									shortname: 'name',
									placeholder: 'Name *',
									value: '',
									type: 'name',
									required: true
								},
								{
									shortname: 'possibleSloganOrTagline',
									placeholder: 'Possible tagline/slogan',
									value: '',
									type: 'text'
								},
								{
									shortname: 'webPage',
									placeholder: 'Web page',
									value: '',
									type: 'text'
								},
								{
									shortname: 'industry',
									placeholder: 'Industry',
									value: '',
									type: 'text'
								}
							]
						},
						{
							id: 2,
							uniqueId: 'companyType',
							type: 'Checkbox',
							title: 'Company type',
							description: 'Select all that apply (optional)',
							options: [
								'Product',
								'Service',
								'B2B',
								'B2C',
								'Personal'
							],
							value: []
						},
						{
							id: 3,
							uniqueId: 'existingLogos',
							type: 'File',
							title: 'Upload your existing logo (if you have one)',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 4,
							uniqueId: 'competitorLogos',
							type: 'File',
							title: 'Upload your Competitors logos here',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 5,
							uniqueId: 'stylePreference',
							type: 'Keyword',
							title: '* Style preference',
							description: 'I prefer a style that is...',
							options: [
								'Nostalgic',
								'Retro',
								'Minimalistic',
								'Whimsical style',
								'Surprise me!'
							],
							required: true,
							max: 1,
							value: []
						},
						{
							id: 6,
							uniqueId: 'aLogoOrBrandYouAdmire',
							type: 'File',
							title: 'Is there a logo that you admire? If you want, I can develop something with the same style and feel. Please upload a picture/logo/screenshot.',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 7,
							uniqueId: 'mustHavesAndDoNotTouchDetails',
							type: 'FreeformText',
							title: 'Do you have any "must haves" or "do not touch" details?',
							description:
								'...or any specific symbol you would like me to explore (anchor, leaf, heart, eye, animal...). Are there any specific or unique details about your company/service/product/brand that is good for me to know, i.e. is it the oldest/cheapest/largest etc. in the field?',
							value: ''
						},
						{
							id: 8,
							uniqueId: 'companyOrBrandPersonality',
							type: 'Box',
							title: "* Your Company/brand's personality",
							description:
								'What impression do you want to give your ideal customers? Customers are more likely to purchase a brand if its personality is similar to their own. Choose ONE that fits the best.',
							options: [
								{
									title: 'Excitement',
									description:
										'carefree, spirited, and youthful'
								},
								{
									title: 'Sincerity',
									description:
										'kindness, thoughtfulness, and an orientation toward family values'
								},
								{
									title: 'Competence',
									description:
										'successful, accomplished, and influential, highlighted by leadership'
								},
								{
									title: 'Sophistication',
									description:
										'elegant, prestigious, and sometimes even pretentious'
								},
								{
									title: 'Ruggedness',
									description:
										'rough, tough, outdoorsy, and athletic'
								}
							],
							required: true,
							max: 1,
							value: []
						},
						{
							id: 9,
							uniqueId: 'personalityAttributesPairs',
							type: 'Keyword',
							title: '* Personality attributes - pairs',
							description:
								'Tick 1-5 attributes you want your brand to convey.',
							options: [
								'Warm and welcoming',
								'Respectable, mature',
								'Reliable and high-end',
								'Glamorous and luxurious',
								'Sophisticated and elegant',
								'Artistic and unique',
								'Clean and straightforward',
								'Informal and simple',
								'Fun and energetic',
								'Authoritative and serious',
								'Feminine and gentle',
								'Masculine and powerful',
								'Light and ethereal',
								'Solid and robust'
							],
							required: true,
							max: 5,
							value: []
						},
						{
							id: 10,
							uniqueId: 'personalityAttributesOpposites',
							type: 'Keyword',
							title: '* Personality attributes - opposites',
							description:
								'Which attribute from the <em>opposites</em> below do you want your brand to convey? Only choose the ones you feel strongly about, you do not have to choose between all of them.',
							options: [
								['Simple', 'Complex'],
								['Youthful', 'Established'],
								['Subtle', 'Bright'],
								['Classic', 'Contemporary'],
								['Friendly', 'Authoritative'],
								['Economical', 'Expensive'],
								['Playful', 'Serious'],
								['Unconventional', 'Mainstream'],
								['Industrial', 'Natural'],
								['Approachable', 'Elite'],
								['Casual', 'Elegant'],
								['Refined', 'Strong']
							],
							required: true,
							value: []
						},
						{
							id: 11,
							uniqueId: 'colorCombinationPreferences',
							type: 'Box',
							title: 'Colour combination preferences (optional)',
							description:
								'What colouring system feels closest to how your want your brand to be perceived? Choose the one that feels most right to you, or you can leave this part empty.',
							options: [
								{
									title: 'Monotone palette - (variations and shades of one colour)',
									description:
										'Elegant, refined, classy, harmonious, quality, calm'
								},
								{
									title: 'Analogous colour palette - combines two or even three adjacent colours on the colour wheel such as yellow + green + blue)',
									description:
										'Harmonious, fresh, easy on the eye, gentle, positive'
								},
								{
									title: 'Complimentary palette - (combines opposite colours from the colour wheel, such as blue + orange, or red + green)',
									description:
										'Strength, energy, vitality, playful, youthful, naive'
								}
							],
							max: 1,
							value: []
						},
						{
							id: 12,
							uniqueId: 'fontCategoryPreferences',
							type: 'Box',
							title: 'Font category preferences (optional)',
							description:
								'Which category feels best suited for your logo? You can leave this part empty.',
							options: [
								{
									title: 'Sans Serif',
									description: 'Modern, geometric, simple.'
								},
								{
									title: 'Serif',
									description: 'Elegant, refined, classic.'
								},
								{
									title: 'Script',
									description: 'Creative, personal, natural.'
								}
							],
							value: []
						},
						{
							id: 13,
							uniqueId: 'targetCustomer',
							type: 'Details',
							title: 'Ideal customer',
							value: [
								{
									shortname: 'age',
									placeholder: 'Age',
									value: '',
									type: 'range'
								},
								{
									shortname: 'gender',
									placeholder: 'Gender',
									value: '',
									type: 'text'
								},
								{
									shortname: 'location',
									placeholder: 'Location',
									value: '',
									type: 'text'
								},
								{
									shortname: 'hobbiesAndInterests',
									placeholder: 'Hobbies/Interests',
									value: '',
									type: 'text'
								},
								{
									shortname: 'jobTitle',
									placeholder: 'Job title',
									value: '',
									type: 'text'
								},
								{
									shortname: 'maritalStatus',
									placeholder:
										'Status married/single/with kids etc.',
									value: '',
									type: 'text'
								},
								{
									shortname: 'goalsOrMotivations',
									placeholder: 'Goals or motivations',
									value: '',
									type: 'text'
								},
								{
									shortname: 'description',
									placeholder:
										'Feel free to describe your ideal customer in your own words',
									value: '',
									type: 'FreeformText'
								}
							]
						},
						{
							id: 14,
							uniqueId: 'targetCustomerChecklist',
							type: 'Box',
							title: '* Ideal customer checklist',
							description:
								"What attributes best describe your ideal customer? Are they the same as your company's personality?",
							options: [
								{
									title: '',
									description:
										'Carefree, spirited, and youthful'
								},
								{
									title: '',
									description:
										'Sincere, kind, thoughtful, and an orientation toward family values'
								},
								{
									title: '',
									description:
										'Successful, accomplished, and influential'
								},
								{
									title: '',
									description:
										'Sophisticated, elegant, and prestigious'
								},
								{
									title: '',
									description:
										'Rough, tough, outdoorsy, and athletic'
								}
							],
							required: true,
							value: []
						},
						{
							id: 15,
							uniqueId: 'anythingElseToConsider',
							type: 'FreeformText',
							title: 'Anything else you want me to consider',
							value: ''
						},
						{
							id: 16,
							type: 'Submit',
							title: 'Terms & Conditions',
							description:
								'By continuing you are agreeing to our <a href="https://custombrandingpro.com/terms" target="_blank">Terms & Conditions</a>.'
						}
					],
					fi: [
						{
							id: 0,
							uniqueId: 'you',
							type: 'Details',
							title: 'Sinä',
							value: [
								{
									shortname: 'name',
									placeholder: 'Nimi *',
									value: '',
									type: 'name',
									required: true
								},
								{
									shortname: 'email',
									placeholder: 'Sähköposti *',
									value: '',
									type: 'email',
									required: true
								},
								{
									shortname: 'phoneNumber',
									placeholder: 'Puhelinnumero',
									value: '',
									type: 'phone'
								},
								{
									shortname: 'position',
									placeholder: 'Asema/nimike',
									value: '',
									type: 'text'
								}
							]
						},
						{
							id: 1,
							uniqueId: 'brandDetails',
							type: 'Details',
							title: 'Yritys/brändi',
							value: [
								{
									shortname: 'name',
									placeholder: 'Nimi *',
									value: '',
									type: 'name',
									required: true
								},
								{
									shortname: 'possibleSloganOrTagline',
									placeholder:
										'Mahdollinen iskulause/slogani',
									value: '',
									type: 'text'
								},
								{
									shortname: 'webPage',
									placeholder: 'Verkkosivusto',
									value: '',
									type: 'text'
								},
								{
									shortname: 'industry',
									placeholder: 'Toimiala',
									value: '',
									type: 'text'
								}
							]
						},
						{
							id: 2,
							uniqueId: 'companyType',
							type: 'Checkbox',
							title: 'Yrityksen tyyppi',
							description:
								'Valitse kaikki soveltuvat (valinnainen)',
							options: [
								'Tuote',
								'Palvelu',
								'B2B',
								'B2C',
								'Henkilökohtainen brändi'
							],
							value: []
						},
						{
							id: 3,
							uniqueId: 'existingLogos',
							type: 'File',
							title: 'Lataa nykyinen oleva logosi (jos sinulla on sellainen)',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 4,
							uniqueId: 'competitorLogos',
							type: 'File',
							title: 'Lataa kilpailijasi logot tästä',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 5,
							uniqueId: 'styleFreference',
							type: 'Keyword',
							title: '* Tyylin valinta',
							description: 'Suosin tyyliä, joka on...',
							options: [
								'Nostalginen',
								'Retro',
								'Minimalistinen',
								'Omalaatuinen',
								'Yllätä minut!'
							],
							required: true,
							max: 1,
							value: []
						},
						{
							id: 6,
							uniqueId: 'aLogoOrBrandYouAdmire',
							type: 'File',
							title: 'Onko sinulla näyttää logoa, jota ihailet? Voin halutessasi kehittää jotain samantyylistä ja tuntuista. Lataa kuva/logo/näyttökuva',
							accept: '.jpg, .jpeg, .png, .svg',
							value: []
						},
						{
							id: 7,
							uniqueId: 'mustHavesAndDoNotTouchDetails',
							type: 'FreeformText',
							title: 'Onko jotain pakollisia yksityiskohtia, jotka haluat välttämättä toteuttaa, tai asioita, joita ei saa missään nimessä lähteä muuttamaan?',
							description:
								'...Tai jokin erityinen symboli, jota haluaisit minun tutkivan (ankkuri, lehti, sydän, silmä, eläin...). Onko yrityksestäsi/palvelustasi/tuotteestasi/brändistäsi mitään erityisiä tai ainutlaatuisia yksityiskohtia, jotka minun on hyvä tietää, esim. onko se alan vanhin/parhaimmin toimiva/tuorein jne.?',
							value: ''
						},
						{
							id: 8,
							uniqueId: 'companyOrBrandPersonality',
							type: 'Box',
							title: '* Yrityksesi/brändisi persoonallisuus',
							description:
								'Minkä vaikutelman haluat antaa ihanneasiakkaillesi? Asiakkaat ostavat todennäköisemmin brändiltä, jonka persoonallisuus muistuttaa heidän omaa persoonallisuuttaan. Valitse YKSI, joka sopii parhaiten.',
							options: [
								{
									title: 'Innostavuus',
									description:
										'huoleton, innostava ja nuorekas'
								},
								{
									title: 'Vilpittömyys',
									description:
										'ystävällisyys, huomaavaisuus ja perhearvoihin suuntautuminen'
								},
								{
									title: 'Kompetenssi',
									description:
										'menestyvä, aikaansaava ja vaikutusvaltainen, jossa johtajuus korostuu'
								},
								{
									title: 'Hienostuneisuus',
									description:
										'tyylikäs, arvostettu ja joskus jopa mahtaileva'
								},
								{
									title: 'Sitkeys',
									description:
										'karu, kova, ulkoileva ja urheilullinen'
								}
							],
							required: true,
							max: 1,
							value: []
						},
						{
							id: 9,
							uniqueId: 'personalityAttributesPairs',
							type: 'Keyword',
							title: '* Persoonallisuusominaisuudet - parit',
							description:
								'Merkitse rastilla 1-5 ominaisuusparia, joiden haluat välittyvän brändistäsi.',
							options: [
								'Lämmin ja vastaanottavainen',
								'Kunnioitettava ja kypsä',
								'Luotettava ja korkeatasoinen',
								'Hohdokas ja ylellinen',
								'Hienostunut ja tyylikäs',
								'Taiteellinen ja ainutlaatuinen',
								'Puhdas ja suoraviivainen',
								'Rento ja selkeä',
								'Hauska ja energinen',
								'Auktoritatiivinen ja vakava',
								'Naisellinen ja lempeä',
								'Maskuliininen ja voimakas',
								'Kevyt ja eteerinen',
								'Vankka ja vahva'
							],
							required: true,
							max: 5,
							value: []
						},
						{
							id: 10,
							uniqueId: 'personalityAttributesOpposites',
							type: 'Keyword',
							title: '* Persoonallisuusominaisuudet - vastakohdat',
							description:
								'Mitkä ominaisuudet alla olevista <em>vastakohdista</em> haluaisit välittyvän brändistäsi? Valitse vain ne, jotka ovat sinulle tärkeitä, sinun ei tarvitse valita kaikkien ominaisuuksien väliltä.',
							options: [
								['Yksinkertainen', 'Monimutkainen'],
								['Nuorekas', 'Vakiintunut'],
								['Hienovarainen', 'Kirkas'],
								['Klassinen', 'Nykyaikainen'],
								['Ystävällinen', 'Auktoriteettinen'],
								['Taloudellinen', 'Kallis'],
								['Leikkisä', 'Vakava'],
								['Epätavallinen', 'Valtavirtainen'],
								['Teollinen', 'Luonnollinen'],
								['Helposti Lähestyttävä', 'Eliitti'],
								['Rento', 'Elegantti'],
								['Hienostunut', 'Voimakas']
							],
							required: true,
							value: []
						},
						{
							id: 11,
							uniqueId: 'colorCombinationPreferences',
							type: 'Box',
							title: 'Väriyhdistelmien mieltymykset (valinnainen)',
							description:
								'Mikä värijärjestelmä tuntuu olevan lähimpänä sitä, miten haluat brändisi näyttäytyvän? Valitse järjestelmä, joka tuntuu sinusta sopivimmalta. Voit myös jättää tämän osan tyhjäksi.',
							options: [
								{
									title: 'Yksivärinen paletti - (yhden värin variaatioita ja sävyjä).',
									description:
										'Elegantti, hienostunut, tyylikäs, harmoninen, laadukas, rauhallinen.'
								},

								{
									title: 'Analoginen väripaletti - (yhdistää kaksi tai jopa kolme vierekkäistä väriä väriympyrässä, kuten keltainen + vihreä + sininen)',
									description:
										'Harmoninen, raikas, silmää miellyttävä, lempeä, positiivinen.'
								},

								{
									title: 'Komplementaarinen paletti - (yhdistelee vastakkaisia värejä väriympyrästä, kuten sininen + oranssi tai punainen + vihreä).',
									description:
										'Voima, energia, elinvoima, leikkisä, nuorekas, naiivi.'
								}
							],
							max: 1,
							value: []
						},
						{
							id: 12,
							uniqueId: 'fontCategoryPreferences',
							type: 'Box',
							title: 'Fonttityyppien vaihtoehdot (valinnainen)',
							description:
								'Mikä fonttityyppi istuu mielestäsi parhaiten logoosi? Voit myös jättää tämän osan tyhjäksi.',
							options: [
								{
									title: 'Sans Serif',
									description:
										'Moderni, geometrinen, yksinkertainen.'
								},
								{
									title: 'Serif',
									description:
										'Tyylikäs, hienostunut, klassinen.'
								},
								{
									title: 'Käsinkirjoitus (Script)',
									description:
										'Luova, persoonallinen, luonnollinen.'
								}
							],
							value: []
						},
						{
							id: 13,
							uniqueId: 'targetCustomer',
							type: 'Details',
							title: 'Ihanteellinen asiakas',
							value: [
								{
									shortname: 'age',
									placeholder: 'Ikä',
									value: '',
									type: 'range'
								},
								{
									shortname: 'gender',
									placeholder: 'Sukupuoli',
									value: '',
									type: 'text'
								},
								{
									shortname: 'location',
									placeholder: 'Sijainti',
									value: '',
									type: 'text'
								},
								{
									shortname: 'hobbiesAndInterests',
									placeholder:
										'Harrastukset/kiinnostuksen kohteet',
									value: '',
									type: 'text'
								},
								{
									shortname: 'jobTitle',
									placeholder: 'Työnimike',
									value: '',
									type: 'text'
								},
								{
									shortname: 'maritalStatus',
									placeholder:
										'Asema (naimisissa/sinkku/lasten kanssa jne.)',
									value: '',
									type: 'text'
								},
								{
									shortname: 'goalsOrMotivations',
									placeholder: 'Tavoitteet tai motiivit',
									value: '',
									type: 'text'
								},
								{
									shortname: 'description',
									placeholder:
										'Kuvaile minulle ihanneasiakastasi vapaasti, omin sanoin.',
									value: '',
									type: 'FreeformText'
								}
							]
						},
						{
							id: 14,
							uniqueId: 'targetCustomerChecklist',
							type: 'Box',
							title: '* Ihanneasiakkaan tarkistuslista',
							description:
								'Mitkä ominaisuudet kuvaavat parhaiten ihanneasiakastasi? Ovatko ne samoja ominaisuuksia, kuin yrityksesi persoonallisuudessa esiintyy?',
							options: [
								{
									title: '',
									description:
										'Huoleton, innokas ja nuorekas.'
								},
								{
									title: '',
									description:
										'Vilpitön, ystävällinen, huomaavainen ja suuntautuminen perhearvoihin'
								},
								{
									title: '',
									description:
										'Menestyvä, ansioitunut ja vaikutusvaltainen'
								},
								{
									title: '',
									description:
										'Hienostunut, tyylikäs ja arvokas.'
								},
								{
									title: '',
									description:
										'Sitkeä, kova, ulkoileva ja urheilullinen.'
								}
							],
							required: true,
							value: []
						},
						{
							id: 15,
							uniqueId: 'anythingElseToConsider',
							type: 'FreeformText',
							title: 'Jotain muuta, mitä haluat minun ottavan huomioon',
							value: ''
						},
						{
							id: 16,
							type: 'Submit',
							title: 'Ehdot ja edellytykset',
							description:
								'Jatkamalla hyväksyt <a href="https://custombrandingpro.com/ehdot" target="_blank">Ehdot & Edellytykset</a>.'
						}
					]
				}
			};
		},
		computed: {
			questions() {
				return this.$store.state.questions;
			}
		},
		mounted() {
			fireSignIn();

			this.basicArtworkPrice = process.env.VUE_APP_PRICE_STARTER;
			this.pk = process.env.VUE_APP_PK;
			this.paymentLink = process.env.VUE_APP_PAYMENT_LINK;

			const maybeLang = localStorage.getItem('lang');
			if (maybeLang) {
				this.$store.commit('setLang', maybeLang);
			}
			this.updateQuestions();
		},
		methods: {
			updateQuestions() {
				this.$store.commit(
					'setQuestions',
					this.questionsByLang[this.$store.state.lang]
				);
			},
			setLang(newLang) {
				this.$store.commit('setLang', newLang);
				window.location = this.$route.fullPath;
			},
			uploadFiles(files, id) {
				return new Promise((resolve, reject) => {
					if (validateFileUpload(files)) {
						// Call the fireUploadFormFiles and ensure it returns a promise
						asyncFireUploadFormFiles(files, id)
							.then(resolve)
							.catch(error => {
								alert(
									'An error occurred while uploading files.'
								);
								reject(error);
							});
					} else {
						alert('File size too large');
						reject(new Error('File size too large'));
					}
				});
			},
			getQuestionByUniqueId(uniqueId) {
				for (const q of this.questions) {
					if (q.uniqueId === uniqueId) {
						return q;
					}
				}

				return { value: 'Error' };
			},
			boxConstructor(questionId, onlyDescription = false) {
				const q = this.getQuestionByUniqueId(questionId);

				const result = [];
				for (const selection in q.value) {
					const description = q.options[selection].description;

					result.push(
						onlyDescription
							? description
							: `${q.options[selection].title}: ${description}`
					);
				}

				return result;
			},
			detailsConstructor(questionId) {
				const q = this.getQuestionByUniqueId(questionId);

				const result = {};
				for (const field of q.value) {
					result[field.shortname] = field.value;
				}

				return result;
			},
			imageConstructor(questionId) {
				const q = this.getQuestionByUniqueId(questionId);
				return !!q.value.length > 0;
			},
			constructFormData(id, timestamp) {
				this.customDeletionId = `${timestamp}_${uuidv4()}`;
				localStorage.setItem('customDeletionId', this.customDeletionId);

				const rawData = {
					timestamp,
					customDeletionId: this.customDeletionId
				};

				for (const q of this.questions) {
					switch (q.type) {
						case 'Details':
							Object.assign(rawData, {
								[q.uniqueId]: this.detailsConstructor(
									q.uniqueId
								)
							});
							break;
						case 'Checkbox':
						case 'FreeformText':
						case 'Keyword':
							Object.assign(rawData, {
								[q.uniqueId]: this.getQuestionByUniqueId(
									q.uniqueId
								).value
							});
							break;
						case 'File':
							Object.assign(rawData, {
								[q.uniqueId]: this.imageConstructor(q.uniqueId)
							});
							break;
						case 'Box':
							Object.assign(rawData, {
								[q.uniqueId]: this.boxConstructor(q.uniqueId)
							});
							break;
						default:
							break;
					}
				}

				return {
					raw: JSON.stringify(rawData)
				};
			},
			async sendForm() {
				if (!this.failedCheckoutOnce) {
					this.failedCheckoutOnce = true;

					const timestamp = Math.round(new Date().getTime());
					const id = `${timestamp}_${uuidv4()}`;

					localStorage.setItem('orderIdForSuccessPage', id);

					const request = this.constructFormData(id, timestamp);

					this.clientData = {
						id,
						email: JSON.parse(request.raw).you.email
					};

					// Sign in to firebase.
					await asyncFireSignIn();

					try {
						// Store the order in firebase and await its completion
						await asyncFirePost(id, request);

						// Collect all file upload promises
						const uploadPromises = [];

						const existingLogos =
							this.getQuestionByUniqueId('existingLogos');
						if (existingLogos.value.length > 0) {
							uploadPromises.push(
								this.uploadFiles(
									existingLogos.value,
									`${id}_logo`
								)
							);
						}

						const competitorLogos =
							this.getQuestionByUniqueId('competitorLogos');
						if (competitorLogos.value.length > 0) {
							uploadPromises.push(
								this.uploadFiles(
									competitorLogos.value,
									`${id}_competitor`
								)
							);
						}

						const aLogoOrBrandYouAdmire =
							this.getQuestionByUniqueId('aLogoOrBrandYouAdmire');
						if (aLogoOrBrandYouAdmire.value.length > 0) {
							uploadPromises.push(
								this.uploadFiles(
									aLogoOrBrandYouAdmire.value,
									`${id}_admire`
								)
							);
						}

						// Wait for all uploads to complete
						await Promise.all(uploadPromises);

						// Now that everything is done, navigate to the Stripe checkout page
						console.log('Navigating to checkout...');
						window.open(
							`${
								this.paymentLink
							}?client_reference_id=${id}&prefilled_email=${encodeURIComponent(
								this.clientData.email
							)}`,
							'_self'
						);
					} catch (e) {
						console.error(e);
						this.failedCheckoutOnce = true;
						alert(
							"We're so sorry but checkout isn't working right now, we have however saved your order. Please contact nina@custombrandingpro.com so that we can continue with your order. We apologise for the inconvenience."
						);
					}
				} else {
					alert(
						"We're so sorry but checkout isn't working right now, we have however saved your order. Please contact nina@custombrandingpro.com so that we can continue with your order. We apologise for the inconvenience."
					);
				}
			},
			setValue(cardIndex, e) {
				try {
					if (this.questions[cardIndex].type === 'FreeformText') {
						this.questions[cardIndex].value = e.target.value;
					} else if (this.questions[cardIndex].type === 'Details') {
						this.questions[cardIndex].value[e[0]].value = e[1];
					}
				} catch (_) {
					// 1/2 times an error is triggered but everything still works for some reason.
				}
			},
			selectValue(cardIndex, e) {
				// Check if is keyword pair
				if (Array.isArray(e)) {
					if (this.questions[cardIndex].value.includes(e[1])) {
						this.questions[cardIndex].value.splice(
							this.questions[cardIndex].value.indexOf(e[1]),
							1
						);
					} else {
						const selectionOwnPair =
							this.questions[cardIndex].options[
								this.questions[cardIndex].options.indexOf(
									this.questions[cardIndex].options[e[0]]
								)
							];
						let otherPair = 0;
						if (selectionOwnPair[0] === e[1]) {
							otherPair = 1;
						}

						if (
							this.questions[cardIndex].value.includes(
								selectionOwnPair[otherPair]
							)
						) {
							this.questions[cardIndex].value.splice(
								this.questions[cardIndex].value.indexOf(
									selectionOwnPair[otherPair]
								),
								1
							);
						}

						this.questions[cardIndex].value.push(e[1]);
					}
				} else {
					if (this.questions[cardIndex].value.includes(e)) {
						this.questions[cardIndex].value.splice(
							this.questions[cardIndex].value.indexOf(e),
							1
						);
					} else {
						this.questions[cardIndex].value.push(e);
					}
				}
			},
			setUploadValue(cardIndex, e) {
				this.questions[cardIndex].value = e;
			}
		}
	};
</script>

<style scoped>
	#lang-select,
	#form {
		background-color: var(--light-grey);
		margin: 0;
	}

	#lang-select {
		margin: 0;
		margin-top: 10vh;
		padding: 0;
		width: fit-content;
		margin-left: auto;
	}

	#lang-select p {
		color: var(--text);
	}

	#lang-select p:hover {
		cursor: pointer !important;
		color: var(--accent);
	}

	#lang-select .ignore-hover {
		opacity: 0.2;
	}

	#lang-select .ignore-hover:hover {
		cursor: initial !important;
		color: var(--text);
	}

	#form {
		padding: 5px 10px 5px 10px;
		min-height: 100vh;
	}
</style>
