<template>
	<input id="send-btn" type="submit" :value="getValue" />
</template>

<script>
	export default {
		name: 'Submit',
		computed: {
			getValue() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'Agree & Continue ➞';
					case 'fi':
						return 'Hyväksyn ja jatkan ➞';
					default:
						return 'Agree & Continue ➞';
				}
			}
		}
	};
</script>

<style scoped>
	#send-btn {
		margin: 10px auto;
		padding: 20px;
		font-size: 1rem;
		background-color: var(--accent-blue);
		color: var(--black);
		border: none;
	}

	#send-btn:hover,
	#send-btn:active {
		cursor: pointer;
		color: var(--text);
	}
</style>
