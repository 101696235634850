<template>
	<div id="keywords">
		<div class="keyword" v-for="(keyword, index) in keywords" :key="index">
			<div v-if="!Array.isArray(keyword)">
				<a @click="$emit('select', keyword)">
					<span v-if="selections.includes(keyword)">
						<strong v-text="keyword"></strong>
					</span>
					<span
						v-else
						v-text="keyword"
						:style="codeIfDisabled('pointer-events: none;', index)"
						:class="codeIfDisabled('disabled-area', index)"
					></span>
				</a>
			</div>

			<div v-else>
				<div
					v-for="(keywordPart, partIndex) in keyword"
					:key="partIndex"
					style="display: inline"
				>
					<a @click="$emit('select', [index, keywordPart])">
						<span v-if="selections.includes(keywordPart)">
							<strong v-text="keywordPart"></strong>
						</span>
						<span v-else v-text="keywordPart"></span>
					</a>

					<span v-if="partIndex === 0"> - </span>
				</div>
			</div>
		</div>

		<input
			type="text"
			:required="isRequired"
			:value="selectionValue"
			class="hidden-validator"
		/>
	</div>
</template>

<script>
	export default {
		name: 'Keyword',
		props: {
			keywords: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			selections: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			isRequired: {
				type: Boolean,
				default: false
			},
			max: {
				type: Number,
				default: 0
			}
		},
		emits: ['select'],
		computed: {
			selectionValue() {
				return this.selections.join('');
			}
		},
		methods: {
			codeIfDisabled(code, index) {
				return this.max > 0 &&
					this.selections.length >= this.max &&
					!this.selections.includes(index)
					? code
					: '';
			}
		}
	};
</script>

<style scoped>
	#keywords {
		margin-top: 15px;
		padding-left: 05px;
	}

	.keyword {
		margin-top: 8px;
		margin-bottom: 8px;
		line-height: 1.5;
		color: var(--text);
		font-size: 0.9rem;
		text-align: left;
	}

	.keyword a span:hover {
		cursor: pointer;
		font-weight: bold;
	}

	.keyword a span strong {
		color: var(--black);
	}

	.disabled-area {
		opacity: 0.5;
	}
</style>
