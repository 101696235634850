<template>
	<div
		class="box"
		v-for="(box, index) in options"
		:key="index"
		@click="$emit('select', index)"
		:style="codeIfDisabled('pointer-events: none;', index)"
		:class="selections.includes(index) ? 'selected' : ''"
	>
		<p :class="codeIfDisabled('disabled-area', index)">
			<span v-if="box.hasOwnProperty('title') && box.title.length > 0">
				<strong v-text="`${box.title}`"></strong>
				<br />
			</span>
			<span
				v-if="box.hasOwnProperty('description')"
				v-text="box.description"
			></span>
		</p>
	</div>

	<input
		type="text"
		:required="isRequired"
		:value="selectionValue"
		class="hidden-validator"
	/>
</template>

<script>
	export default {
		name: 'Box',
		props: {
			options: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			selections: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			isRequired: {
				type: Boolean,
				default: false
			},
			max: {
				type: Number,
				default: 0
			}
		},
		emits: ['select'],
		computed: {
			selectionValue() {
				return this.selections.length > 0 ? 'ok' : '';
			}
		},
		methods: {
			codeIfDisabled(code, index) {
				return this.max > 0 &&
					this.selections.length >= this.max &&
					!this.selections.includes(index)
					? code
					: '';
			}
		}
	};
</script>

<style scoped>
	.box {
		padding: 8px 5px;
		margin-top: 2px;
		margin-bottom: 2px;
		border-top: 5px solid var(--white);
		border-bottom: 5px solid var(--white);
		text-align: left;
	}

	.box:hover {
		cursor: pointer;
		background-color: var(--light-grey);
	}

	.selected,
	.selected:active,
	.selected:hover {
		background-color: var(--accent-blue);
		border-color: var(--accent-blue);
	}

	p {
		color: var(--text);
		font-size: 0.9rem;
	}

	strong {
		font-style: bold;
		color: var(--black);
	}

	.disabled-area {
		opacity: 0.5;
	}
</style>
