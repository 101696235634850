<template>
	<div class="row parent">
		<div class="col-xs-12 no-padding">
			<div v-for="(option, index) in options" :key="index" class="row">
				<div class="col-xs-6 no-padding">
					<input type="text" disabled :placeholder="option" />
				</div>
				<div class="col-xs-6 vc no-padding">
					<input
						type="checkbox"
						:checked="selections.includes(option)"
						@change="$emit('select', [index, option])"
					/>
				</div>
			</div>
		</div>
	</div>

	<input
		class="hidden-validator"
		:required="isRequired"
		:value="selections.join('')"
	/>
</template>

<script>
	export default {
		name: 'CheckboxInput',
		props: {
			options: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			selections: {
				type: Array,
				default: function _() {
					return [];
				}
			},
			isRequired: {
				type: Boolean,
				default: false
			}
		},
		emits: ['select']
	};
</script>

<style scoped>
	.parent {
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
		margin: 0 auto;
	}

	.no-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.no-padding input {
		width: 100%;
	}

	.vc input {
		margin-left: 20%;
	}

	h3 {
		font-size: 0.8rem;
		padding: 0;
		margin-left: 0;
		text-align: left;
	}
</style>
