<template>
	<div class="row parent">
		<div class="col-xs-3 no-padding">
			<input type="text" disabled :placeholder="field.placeholder" />
		</div>
		<div class="col-xs-9 no-padding">
			<div class="row">
				<div class="col-xs-5">
					<input
						:placeholder="getMinPlaceholder"
						type="number"
						v-model="min"
						@input="rangeChange(field, 'min', $event)"
					/>
				</div>
				<div class="col-xs-2">
					<input class="center" type="text" disabled value="-" />
				</div>
				<div class="col-xs-5">
					<input
						:placeholder="getMaxPlaceholder"
						type="number"
						v-model="max"
						@input="rangeChange(field, 'max', $event)"
					/>
				</div>
			</div>
		</div>

		<input
			class="hidden-validator"
			:required="field?.required"
			:value="rangeRequiredValue"
		/>
	</div>
</template>

<script>
	export default {
		name: 'RangeInput',
		props: {
			field: {
				type: Object,
				default: function _() {
					return {
						type: 'range',
						value: null,
						placeholder: ''
					};
				}
			}
		},
		emits: ['input'],
		data() {
			return {
				min: '',
				max: ''
			};
		},
		methods: {
			rangeChange(field, type, e) {
				if (!e.target?.value) {
					return;
				}

				if (type === 'min') {
					this.$emit(
						'input',
						`${e.target?.value ? e.target.value : 0}-${this.max}`
					);
				}

				this.$emit(
					'input',
					`${this.min}-${e.target?.value ? e.target.value : 0}`
				);
			}
		},
		computed: {
			getMinPlaceholder() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'min';
					case 'fi':
						return 'minimi';
					default:
						return 'min';
				}
			},
			getMaxPlaceholder() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'max';
					case 'fi':
						return 'maksimi';
					default:
						return 'max';
				}
			},
			rangeRequiredValue() {
				if (this.max > 0) {
					return 'ok';
				}

				return '';
			}
		}
	};
</script>

<style scoped>
	.parent {
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
	}

	.no-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.no-padding input {
		width: 100%;
	}

	.center {
		text-align: center;
	}
</style>
