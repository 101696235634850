<template>
	<div class="field" v-for="(field, index) in fields" :key="index">
		<RangeInput
			v-if="field.type === 'range'"
			:field="field"
			@input="$emit('input', [index, $event])"
		/>
		<textarea
			v-else-if="field.type === 'FreeformText'"
			cols="30"
			rows="5"
			@input="$emit('input', [index, $event.target.value])"
			:required="field?.required"
			:placeholder="field.placeholder"
		></textarea>
		<input
			v-else
			:placeholder="field.placeholder"
			:value="field.value"
			:type="field.type"
			@input="$emit('input', [index, $event.target.value])"
			:required="field?.required"
		/>
	</div>
</template>

<script>
	import RangeInput from './RangeInput';
	export default {
		name: 'Details',
		components: {
			RangeInput
		},
		props: {
			fields: {
				type: Object,
				default: function _() {
					return {
						type: '',
						value: ''
					};
				}
			}
		},
		emits: ['input']
	};
</script>

<style scoped></style>
