<template>
	<textarea
		cols="30"
		rows="5"
		@input="$emit('input', $event)"
		:required="isRequired"
		:placeholder="getPlaceholder"
	></textarea>
</template>

<script>
	export default {
		name: 'FreeformText',
		props: {
			isRequired: {
				type: Boolean,
				default: false
			}
		},
		emits: ['input'],
		computed: {
			getPlaceholder() {
				switch (this.$store.state.lang) {
					case 'eng':
						return 'Start typing...';
					case 'fi':
						return 'Aloita kirjoittaminen...';
					default:
						return 'Start typing...';
				}
			}
		}
	};
</script>

<style scoped></style>
